.has-error {
    border: 1px solid red !important;
}

input[type='radio'].has-error::after,
input[type='checkbox'].has-error::after {
    display: block;
    width: 100%;
    height: 100%;
    background: transparent;
    content: '';
    border: 2px solid red !important;
    box-sizing: border-box;
}

input[type='radio']::after {
    border-radius: 100%;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f1f1f1 !important;
}

#ui-datepicker-div {
    z-index: 10000 !important;
}

.custom-backdrop {
    background-color: rgba(0, 0, 0, 0.3) !important;
    position: fixed;
    width: 100%;
    height: 300% !important;
    z-index: 99999;
    top: 0;
    left: 0;
}

body {
    background-color: #f4f3ef !important;
}

.pad-desktop {
    margin-left: 12px;
}

.select2-selection__rendered {
    padding: 3px !important;
}

@media only screen and (max-width: 500px) {
    .mobile-hide {
        display: none !important;
    }

    .table-responsive {
        overflow: auto;
        display: block;
    }

    .form-check-radio {
        margin-left: 30px;
    }
}

@page {
    size: A4;
    margin: 0;
}

@media print {

	html,
	body {
		height: 200%;
		margin: 0 !important;
		padding: 0 !important;
		width: 100%;
	}

    .collapse:not(.show) {
        display: inline !important;
    }

    .no-print,
    .no-print * {
        display: none !important;
    }

    .main-panel .main-content {
        margin-top: 0px;
    }

    .main-panel {
        float: left;
        margin: auto;
        width: 100%;
    }

    .col-print-12 {
        width: 100% !important;
    }
}

.sidebar .logo a.logo-custom {
    text-align: center;
    margin-left: 10px;
    margin-right: 12px;
}

.sidebar-minimizer {
    padding: 0;
    margin: 0;
    line-height: 0;
    border: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(calc(50% - 2px), -50%);
    background: white;
    width: 27px;
    height: 27px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #66615b;
    animation: 3s infinite alternate slidein;
}

.sidebar-minimizer:focus {
    outline: none;
}

.sidebar-minimizer::before {
    content: '\ea3a';
    font: normal normal normal 14px/1 'nucleo-icons';
    font-size: 13pt;
    font-weight: bold;
    color: #66615b;
    padding: 0;
    margin: 0;
    line-height: 27px;
    position: absolute;
    top: -2px;
    left: 2px;
    animation: 3s infinite alternate slidein;
}

.pointer-events-none {
    pointer-events: none !important;
}

.sidebar-mini .sidebar-minimizer {
    transform: translate(70%, -50%);
}

.sidebar-mini .sidebar-minimizer::before {
    transform: rotate(180deg);
    left: 4px;
}

.logo-image>img {
	width: 150px;
}

.txt-area-height {
    max-height: 100% !important;
}

.backdrop {
    position: fixed !important;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    background-color: #66615b !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background-color: #66615b !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    background-color: #66615b !important;
}

.k-menu-horizontal {
    display: none;
}

.trv-search-dialog.k-widget.trv-hidden {
    display: none;
}

.trv-search-dialog-results {
    display: none;
}

.trv-side-menu.k-content,
.trv-error-pane {
    display: none;
}

.p-dropdown-panel {
    max-width: 100% !important;
}

.p-dropdown-item.p-ripple {
    white-space: normal;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: #66615b !important;
}

.note {
    font-size: 10px;
    color: #66615b !important;
}

.p-autocomplete {
    width: 100% !important;
}

.highlight {
    background-color: #ffa;
}

.highlight {
    background-color: #ffa;
}

.p5 {
    padding: 5px !important;
}

.p10 {
    padding: 10px !important;
}

.p20 {
    padding: 20px !important;
}

.m5 {
    margin: 5px !important;
}

.m10 {
    margin: 10px !important;
}

.m20 {
    margin: 20px !important;
}

.p-multiselect {
    width: 100% !important;
}

.p-paginator,
.p-dropdown {
    width: 100% !important;
}

.p-paginator,
.p-paginator-current {
    color: #66615b !important;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator,
.p-paginator-last {
    color: #66615b !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    border-color: #66615b !important;
    background: #66615b !important;
    color: white !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
    color: #66615b !important;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
	background: #66615b;
	color: #ffffff;
}

.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #66615b !important;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
    color: white !important;
    background: #66615b;
}

.p-inputtext {
    width: 100% !important;
}

.p-autocomplete {
    min-width: 300px !important;
    max-width: 300px !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.2rem rgb(102 97 91 / 50%);
}

.card-title {
    margin-top: 0px !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid;
    border-width: 1px;
    border-color: #ffffff #ffffff #dee2e6 #ffffff;
    background: #66615b5c;
    color: #6c757d;
    padding: 7px;
    font-weight: 600;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    transition: box-shadow 0.15s;
    margin: 0 0 -1px 0;
    height: 100%;
}

.main-panel .main-content {
    margin-top: 37px !important;
}

.p-l-r-0 {
    padding-left: 0;
    padding-right: 0;
}

.full-div {
    display: inline-block;
    width: 100%;
}

.space-20 {
    height: 20px;
}

.space-40 {
    height: 40px;
}

.space-60 {
    height: 60px;
}

.flex-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.btn-cntnr {
    display: inline-block;
    float: right;
}

@media (max-width: 1200px) {
    .flex-div.big-one {
        display: inline-block;
        width: 100%;
        font-size: 20px;
    }
}

@media (max-width: 1200px) {
    .flex-div.big-one {
        font-size: 16px;
    }
}

@media (max-width: 767px) {
    div.dataTables_wrapper div.dataTables_paginate ul.pagination {
        justify-content: center !important;
    }
}

@media (max-width: 575px) {
	.flex-div {
		display: inline-block;
		text-align: center;
	}

	.flex-div button {
		display: block;
		margin: 10px auto;
		float: none !important;
	}

	.flex-div.big-one {
		font-size: 20px;
		text-align: center;
	}

	.btn-cntnr {
		width: 100%;
		float: none;
		text-align: center;
	}

	.btn-cntnr .float-right {
		float: none !important;
	}

	.btn-cntnr .btn-group {
		margin-left: 5px !important;
		margin-right: 5px !important;
	}

    .p-tabview-nav li .p-tabview-nav-link {
        font-size: 14px;
    }
}

textarea {
    max-width: 100% !important;
    min-width: 100%;
}

.btn-warning {
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
	.footer nav {
		display: inline-block;
		margin-bottom: 0px;
		float: left;
	}

	.btnss button {
		margin-bottom: 10px !important;
	}

    .m-t-c {
        text-align: center;
    }
}

@media screen and (max-width: 575px) {
	.footer nav {
		display: inline-block;
		margin-bottom: 0px;
		float: none;
		width: 100%;
		text-align: center;
	}

	.copyright {
		text-align: center;
		display: inline-block;
		width: 100%;
	}

	.main-panel .main-content {
		padding-left: 15px;
		padding-right: 15px;
	}

	.sm-m-20 {
		margin-bottom: 20px;
	}
}

/* 
p-l-r-0
offset-lg-1 col-lg-5 col-md-6


http://localhost:4200/#/forms/lease-evaluation */
.p-selectbutton .p-button.p-highlight {
    background: #66615b;
    border-color: #66615b;
    color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover {
    background: #66615b;
    border-color: #66615b;
    color: #ffffff;
}

.p-selectbutton .p-button {
    background: #c6c6c6;
    border: 1px solid #d7d3d3;
    color: #ffffff;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #66615be8;
    border-color: #66615b;
    color: #ffffff;
}

.p-selectbutton .p-button {
    font-size: 0.8rem;
}

#test-environment {
	/* position: sticky; */
	top: 0;
	/* width: 70%; */
	height: 3em;
	display: flex;
    background-color: transparent;
	justify-content: space-around;
	/*background-color: rgb(133, 170, 163);*/
	font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.postion-absolute {
    position: absolute !important;
    margin-top: 0.5rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.navbar-nav .dropdown-menu:after {
    right: 0 !important;
}

.p-inputnumber,
p-inputnumber {
    display: block !important;
}

span.p-inputnumber.p-component {
    display: block !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
    left: unset !important;
    right: 0 !important;
    width: max-content;
    max-width: 18em;
}

.navbar-expand-lg .navbar-nav .dropdown-menu li {
    width: 100%;
}

.navbar-expand-lg .navbar-nav .dropdown-menu a {
    padding: 8px 16px;
    width: 100%;
    font-size: 12px;
    display: inline-block;
    text-decoration: none !important;
}

.navbar-expand-lg .navbar-nav .dropdown-menu li:first-child a {
    border-radius: 10px 10px 0 0;
}

.navbar-expand-lg .navbar-nav .dropdown-menu li:last-child a {
    border-radius: 0 0 10px 10px;
    background-color: #f5f5f5;
    text-align: center;
}

.navbar-expand-lg .navbar-nav .dropdown-menu a:hover {
    background-color: #f5f5f5;
    color: #ef8157;
}

.navbar-expand-lg .navbar-nav .dropdown-toggle {
    margin-left: 15px;
}

.navbar-expand-lg .navbar-nav .dropdown-toggle:after {
    display: none;
}

.navbar-expand-lg .navbar-nav .dropdown-toggle sup {
    display: flex;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: red;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: #fff;
    position: absolute;
    right: -7px;
    top: -6px;
}

.navbar-expand-lg .navbar-nav .dropdown-toggle .fa {
    font-size: 30px;
}

.p-disabled,
.p-component:disabled {
    background-color: #f1f1f1 !important;
    color: #66615b !important;
    cursor: not-allowed;
    opacity: 1;
}

.p-inputtext {
    font-family: 'Montserrat', 'Helvetica Neue', Arial, sans-serif !important;
    font-size: 14px !important;
}

.pre-wrap {
    white-space: pre-wrap;
}

.invalid-feedback-password-length {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.required:after {
    content: ' *';
    color: red;
}

select option:disabled {
    color: #999999;
    background-color: #f7f7f7;
    cursor: not-allowed;
}


.ag-paging-panel {
	height: auto !important;
	min-height: 48px !important;
}

.ag-paging-panel>* {
	margin: 0 12px !important;
}


.increased-text-size {
	font-size: 17px !important;
}

.inputSize input,
textarea {
	font-size: 19px !important;
}

body .fc-v-event .fc-event-title {
	white-space: pre-line !important;
}

.error {
    color: red;
    display: inline;
}

.btn-login {
    margin-bottom: 10px;
}

.btn-login:hover, 
.btn-login:active {
    background: linear-gradient(#20346d, #101a37);
}

#login-test-environment {
    position: fixed; 
    top: 0;
    width: 100%; 
    height: 3em;
    display: flex;
    justify-content: space-around;
    background-color: none;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    z-index: 1000; 
}

.icon-gold-color{
	color: #c2a76a !important;
}

.navy-bg-color{
	background-color: #20346d;
}

.navy-font-color{
	color: #20346d;
}

.gold-font-color{
	color: #c2a76a;
}

.info-tooltip {
    color: red;
    cursor: pointer;
}